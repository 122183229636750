import authentication from './authentication.json'
import insurance_offer from './insurance_offer.json'
import completion from './completion.json'
import properties from './properties.json'
import not_found from './not_found.json'
import intro from './intro.json'
import invoicing from './invoicing.json'

const pages = {
  introduction: intro,
  authentication,
  properties,
  insurance_offer,
  completion,
  invoicing
}

const getPage = (page) => {
  if(!page) {
    return intro
  }
  if(pages[page]) {
    return pages[page]
  }
  return not_found
}

export { getPage }