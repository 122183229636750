import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { getPage } from '../pages'
import JSONPretty from 'react-json-pretty'
import { Collapse, IconButton } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

const Content = () => {

  const history = useHistory()
  const location = useLocation()

  const jsonTheme = {
    main: 'line-height:1.3;color:#848788;overflow:auto;font-size:11px',
    key: 'color:#000b5f;',
    string: 'color:#007bff;',
    value: 'color:#a6e22e;',
    boolean: 'color:#ac81fe;',
  }

  const baseUrl = 'https://api.assuranceski.com'
  // const baseUrl = 'http://localhost:8080'

  const [currentPage, setCurrentPage] = useState('authentication')
  const [currentPath, setCurrentPath] = useState('')

  const [sectionsCollapsed, setSectionsCollapsed] = useState({})

  const requestId = (s) => {
    return `${s.method}_${s.path}`
  }

  const toggleSection = (s) => {
    setSectionsCollapsed({
      ...sectionsCollapsed,
      [requestId(s)]: !Boolean(sectionsCollapsed[requestId(s)])
    })
  }

  useEffect(() => {
    setCurrentPage(location.pathname.substring(1))
    setCurrentPath(location.hash.substring(1))
  }, [location])

  const renderSection = (s, si) => {
    const textTypes = ['title', 'subtitle', 'text', 'list_item', 'semi_title']
    if(textTypes.includes(s.type)) {
      return (
        <div key={`content_section_${si}`} className={`content ${s.type}`} dangerouslySetInnerHTML={{ __html: s.content }}>
        </div>
      )
    } else if(s.type === 'separator') {
      return <div key={`content_section_${si}`} className="content separator" />
    } else if(s.type === 'endpoint') {
      return renderEndpoint(s, si)
    }
  }

  const curlExample = (s) => {
    let curlString = ''
    curlString += `curl  --request ${s.method.toUpperCase()}`
    let url = `${baseUrl}${s.path}`
    if(s.request.path_params) {
      for(let i in s.request.path_params) {
        console.log(`Replace :${s.request.path_params[i].key} with ${s.request.path_params[i].example}`)
        url = url.replace(`:${s.request.path_params[i].key}`, s.request.path_params[i].example)
      }
    }
    if(s.request.query_params && s.request.query_params.length > 0) {
      let query = ''
      for(let i in s.request.query_params) {
        query += `${query === '' ? '?' : '&'}${s.request.query_params[i].key}=${s.request.query_params[i].example}`
      }
      url += query
    }
    curlString += `  \\\n  --url ${url}`
    for(let i in s.request.headers) {
      curlString += `  \\\n  --header '${s.request.headers[i].key}: ${s.request.headers[i].example}'`
    }
    if(s.request.body && s.request.body.length > 0) {
      curlString += `  \\\n  --header 'Content-Type: application/json'`
      let body = {}
      for(let i in s.request.body) {
        body[s.request.body[i].key] = s.request.body[i].example
      }
      curlString += `  \\\n  --data '${JSON.stringify(body, null, 4)}'`
    }
    return curlString
  }
  

  const renderEndpoint = (s, si) => {
    return (
      <div key={`content_section_${si}`} className="endpoint__wrapper">
        <div className="endpoint__title-wrapper">
          <span className={`method ${s.method}`}>{ s.method.toUpperCase() }</span>
          <span className="endpoint__title">{ s.title }</span>
        </div>
        { s.note && <div className="endpoint__note" dangerouslySetInnerHTML={{ __html: s.note }} /> }
        <code>{ baseUrl }<b>{ s.path }</b></code>
        <div className="endpoint__description" dangerouslySetInnerHTML={{ __html: s.description }} />
        { s.request && <div className="endpoint__request-wrapper">
            <span className="endpoint__request-title">Request</span>
            { (s.request.headers && s.request.headers.length > 0) && (
              <div className="endpoint__request-section">
                <span className="endpoint__request-section-title">Headers</span>
                <div className="endpoint__request-section-wrapper">
                { s.request.headers.map((h, hi) => {
                  return (
                    <div key={`header_${hi}`} className="endpoint__request-item">
                      <div className="endpoint__request-item-key">{h.key}</div>
                      <div className={`endpoint__request-item-level ${h.required ? 'required' : 'optional'}`}>{h.required ? 'required' : 'optional'}</div>
                      <div className="endpoint__request-item-type">{h.type}</div>
                      <div className="endpoint__request-item-description" dangerouslySetInnerHTML={{ __html: h.description }} />
                    </div>
                  )
                })}
                </div>
              </div>
            ) }
            { (s.request.path_params && s.request.path_params.length > 0) && (
              <div className="endpoint__request-section">
                <span className="endpoint__request-section-title">Path parameters</span>
                <div className="endpoint__request-section-wrapper">
                { s.request.path_params.map((h, hi) => {
                  return (
                    <div key={`path_param_${hi}`} className="endpoint__request-item">
                      <div className="endpoint__request-item-key">{h.key}</div>
                      <div className={`endpoint__request-item-level ${h.required ? 'required' : 'optional'}`}>{h.required ? 'required' : 'optional'}</div>
                      <div className="endpoint__request-item-type">{h.type}</div>
                      <div className="endpoint__request-item-description" dangerouslySetInnerHTML={{ __html: h.description }} />
                    </div>
                  )
                })}
                </div>
              </div>
            ) }
            { (s.request.query_params && s.request.query_params.length > 0) && (
              <div className="endpoint__request-section">
                <span className="endpoint__request-section-title">Query parameters</span>
                <div className="endpoint__request-section-wrapper">
                { s.request.query_params.map((h, hi) => {
                  return (
                    <div key={`query_param_${hi}`} className="endpoint__request-item">
                      <div className="endpoint__request-item-key">{h.key}</div>
                      <div className={`endpoint__request-item-level ${h.required ? 'required' : 'optional'}`}>{h.required ? 'required' : 'optional'}</div>
                      <div className="endpoint__request-item-type">{h.type}</div>
                      <div className="endpoint__request-item-description" dangerouslySetInnerHTML={{ __html: h.description }} />
                    </div>
                  )
                })}
                </div>
              </div>
            ) }
            { (s.request.body && s.request.body.length > 0) && (
              <div className="endpoint__request-section">
                <span className="endpoint__request-section-title">Body</span>
                <div className="endpoint__request-section-wrapper">
                { s.request.body.map((b, hi) => {
                  return (
                    <div key={`body_param_${hi}`} className="endpoint__request-item">
                      <div className="endpoint__request-item-key">{b.key}</div>
                      <div className={`endpoint__request-item-level ${b.required ? 'required' : 'optional'}`}>{b.required ? 'required' : 'optional'}</div>
                      <div className="endpoint__request-item-type">{b.type}</div>
                      <div className="endpoint__request-item-description" dangerouslySetInnerHTML={{ __html: b.description }} />
                    </div>
                  )
                })}
                </div>
              </div>
            ) }
            <div className="endpoint__request-section-title">Example:</div>
            <div className="endpoint__request-curl-wrapper">
              { curlExample(s) }
            </div>
          </div>
        }
        { s.response && <div className="endpoint__request-wrapper">
            <div className="endpoint__request-title">Response</div>
            { s.response.description ? (
              <div className="endpoint__description">{ s.response.description }</div>
            ) : null }
            {/* <div className="endpoint__request-section-title">Schema:</div>
            <code><a href={`${s.response.schema.url}`} target="_blank" rel="noreferrer">{ s.response.schema.label }</a></code> */}
            <div className="endpoint__request-section-title">Example:</div>
            <div className="endpoint__request-curl-wrapper">
              <IconButton onClick={() => toggleSection(s)} className="endpoint__request-example-button" size="small">
                { Boolean(sectionsCollapsed[requestId(s)]) ? <ExpandMore /> : <ExpandLess /> }
              </IconButton>
              <Collapse className={"endpoint__request-example-collapse"} in={!Boolean(sectionsCollapsed[requestId(s)])}>
                <JSONPretty data={s.response.example} theme={jsonTheme} space={2} />
              </Collapse>
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <div>
      { getPage(currentPage).map(renderSection) }
    </div>
  )

}

export default Content