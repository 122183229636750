import React, { useState } from 'react'
import { AppBar, Box, Container, CssBaseline, Grid, Slide, Toolbar, useScrollTrigger } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import logo from './assets/images/logo_assurglisse_desktop.png'
import Sidebar from './Components/Sidebar';
import Content from './Components/Content';
import {
  BrowserRouter as Router, Route,
} from "react-router-dom";


function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000"
    },
  },
});

function App() {

  return (
    <Router>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <HideOnScroll>
            <AppBar>
              <Toolbar>
                <img src={logo} height={30} alt="logo" />
                <h4 className="logo-text">API</h4>
              </Toolbar>
            </AppBar>
          </HideOnScroll>
          {/* <Toolbar /> */}
          <Box className="v-container app">
            <Grid container direction="row" className="v-container">
              <Grid item xs={3} className="column sidebar">
                <Sidebar />
              </Grid>
              <Grid item xs={9} className="column content">
                <Content />
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </React.Fragment>
    </Router>
  );
}

export default App;
