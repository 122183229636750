import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import sidebarSections from '../sidebar.json'

const Sidebar = () => {

  const history = useHistory()
  const location = useLocation()

  const [selectedLink, setSelectedLink] = useState('')


  useEffect(() => {
    setSelectedLink(`${location.pathname.substring(1)}${location.hash}`)
  }, [location])

  const handleClick = (section) => (e) =>  {
    if(!section.page) {
      return
    }
    history.push(`/${section.page}${section.path || ''}`)
    // setSelectedLink(section.path)
  }

  const isSelected = (section) => {
    if(!selectedLink && section.default) {
      return true
    }
    return selectedLink === `${section.page}${section.path || ''}`
  }

  // console.log(sidebarSections)

  return (
    <Grid container direction="column">
      { sidebarSections.map((section, si) => {
        return (
          <>
            <Grid item onClick={handleClick(section)} key={`sidebar_row_${si}`} className={`sidebar row ${section.type} ${isSelected(section) ? 'selected' : ''}`}>
              { section.label }
            </Grid>
            {/* { (isSelected(section) && section.endpoints) && 
              <div className="subsections-wrapper"> 
                { section.endpoints.map((endpoint, ei) =>
                  <div key={`sidebar_row_${si}_endpoint_${ei}`} className={`sidebar subsection row ${section.type}`}>
                    { endpoint.label }
                  </div>
                )}
              </div>
            } */}
          </>
        )
      })}
    </Grid>
  )
}

export default Sidebar